.home-projects {
  position: relative;
  padding-top: 80rem;
}

.home-fixed {
  position: fixed;
  z-index: 2;
}

.home-main--active {
  z-index: 2;
}

.home-main--inactive {
  z-index: 0;
}

.home-main-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 5rem 20vh auto;
  gap: 0 1rem;
  padding: 2rem 2rem 10rem;
}

.home-heading {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.home-about-link {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

.home-name {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  padding-bottom: 8rem;
}

.home-selected-work {
  grid-column: 1 / 4;
  grid-row: 4 / 5;
  padding-bottom: 0.7rem;
}

.home-selected-work-sub {
  grid-column: 1 / 4;
  grid-row: 5 / 6;
}

@media only screen and (min-width: 50rem) {
  .home-main-grid {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 3rem 20vh auto;
    padding: 2rem 2rem;
  }

  .home-heading {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .home-about-link {
    grid-column: 5 / 8;
    grid-row: 1 / 2;
  }

  .home-name {
    grid-column: 5 / 9;
    grid-row: 3 / 4;
    padding-bottom: 8rem;
  }

  .home-selected-work {
    grid-column: 5 / 9;
    grid-row: 4 / 5;
    padding-bottom: 0.7rem;
  }

  .home-selected-work-sub {
    grid-column: 5 / 9;
    grid-row: 5 / 6;
  }
}

@media only screen and (min-width: 70rem) {
  .home-main-grid {
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 3rem 20vh auto;
  }

  .home-heading {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .home-about-link {
    grid-column: 7 / 9;
    grid-row: 1 / 2;
  }

  .home-name {
    grid-column: 7 / 11;
    grid-row: 3 / 4;
    padding-bottom: 8rem;
  }

  .home-selected-work {
    grid-column: 7 / 13;
    grid-row: 4 / 5;
    padding-bottom: 0.6rem;
  }

  .home-selected-work-sub {
    grid-column: 7 / 13;
    grid-row: 5 / 6;
  }
}

.project-overlay {
  padding: 0 2rem;
}

.project-padding-top {
  padding-top: 1rem;
}

.project-back-button {
  padding-bottom: 4rem;
  padding-top: 2rem;
}

.project-overlay-image {
  padding-bottom: 2rem;
}

.project-overlay {
  left: 0;
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.project-overlay--hidden {
  bottom: -100vh;
  transition-delay: 0s;
}

.project-overlay--active {
  bottom: 0;
  transition-delay: 0s;
}

@media only screen and (min-width: 50rem) {
  .project-overlay-image {
    padding-bottom: 0;
  }

  .project-overlay {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(6, auto);
    gap: 0 1rem;
    padding: 2rem 2rem;
  }

  .project-back-button {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  .project-overlay-title {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
  }

  .project-overlay-description {
    grid-column: 1 / 5;
    grid-row: 3 / 6;
  }

  .project-overlay-role {
    grid-column: 5 / 9;
    grid-row: 3 / 4;
  }

  .project-overlay-credits {
    grid-column: 5 / 9;
    grid-row: 4 / 5;
  }

  .project-overlay-visit {
    grid-column: 5 / 9;
    grid-row: 5 / 6;
  }

  .project-overlay-images {
    grid-column: 1 / 9;
    grid-row: 6 / 7;
  }

  /* project overlay image grid rules */

  .project-overlay-image-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(auto);
    gap: 2rem 2rem;
  }

  .overlay-image-grid-item-1 {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  .overlay-image-grid-item-2 {
    grid-column: 5 / 9;
    grid-row: 1 / 2;
  }

  .overlay-image-grid-item-3 {
    grid-column: 1 / 9;
    grid-row: 2 / 3;
  }

  .overlay-image-grid-item-4 {
    grid-column: 1 / 5;
    grid-row: 3 / 4;
  }

  .overlay-image-grid-item-5 {
    grid-column: 5 / 9;
    grid-row: 3 / 4;
  }

  .overlay-image-grid-item-6 {
    grid-column: 1 / 9;
    grid-row: 4 / 5;
  }
}

@media only screen and (min-width: 70rem) {
  .project-overlay {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, auto);
  }

  .project-back-button {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  .project-overlay-title {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .project-overlay-description {
    grid-column: 1 / 6;
    grid-row: 3 / 6;
  }

  .project-overlay-role {
    grid-column: 7 / 13;
    grid-row: 3 / 4;
  }

  .project-overlay-credits {
    grid-column: 7 / 13;
    grid-row: 4 / 5;
  }

  .project-overlay-visit {
    grid-column: 7 / 13;
    grid-row: 5 / 6;
  }

  .project-overlay-images {
    grid-column: 1 / 13;
    grid-row: 6 / 7;
  }

  .project-overlay-image-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(auto);
    gap: 1rem 1rem;
  }

  .overlay-image-grid-item-1 {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }

  .overlay-image-grid-item-2 {
    grid-column: 7 / 13;
    grid-row: 1 / 2;
  }

  .overlay-image-grid-item-3 {
    grid-column: 1 / 13;
    grid-row: 2 / 3;
  }

  .overlay-image-grid-item-4 {
    grid-column: 1 / 7;
    grid-row: 3 / 4;
  }

  .overlay-image-grid-item-5 {
    grid-column: 7 / 13;
    grid-row: 3 / 4;
  }

  .overlay-image-grid-item-6 {
    grid-column: 1 / 13;
    grid-row: 4 / 5;
  }
}

@import url("https://use.typekit.net/xdz4fhe.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.05rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 3rem;
  line-height: 2.8rem;
  font-weight: 800;
}

h2 {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

p {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05rem;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

small {
  font-size: 16px;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.05rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-gray {
  color: #5a5a5a;
}

a {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
  color: #ffa10b;
}

a:visited {
  text-decoration: none;
  color: #ffa10b;
}

li {
  list-style-type: none;
}

body::-webkit-scrollbar {
  width: 0.7vw;
}

body::-webkit-scrollbar-thumb {
  background-color: grey;
  border: solid 3px transparent;
  border-radius: 9999px;
}

.project-overlay::-webkit-scrollbar {
  width: 0.7vw;
}

.project-overlay::-webkit-scrollbar-thumb {
  background-color: grey;
  border: solid 3px transparent;
  border-radius: 9999px;
}

/* Page transition rules */
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffa10b;
  transform-origin: right;
  z-index: 10;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffa10b;
  transform-origin: left;
  z-index: 10;
}

@media only screen and (min-width: 50rem) {
  h1 {
    font-size: 4rem;
    line-height: 3.5rem;
  }

  h2 {
    font-size: 2.3rem;
    line-height: 2.5rem;
  }

  h3 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  p,
  a,
  small {
    font-size: 1.1rem;
  }
}

@media only screen and (min-width: 70rem) {
  h1 {
    font-size: 7rem;
    line-height: 6rem;
  }

  h2 {
    font-size: 3rem;
    line-height: 3.2rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 2rem;
  }

  p,
  a,
  small {
    font-size: 1rem;
  }
}

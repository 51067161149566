.project {
  padding: 0 2rem;
}

p.project-link:hover {
  cursor: pointer;
}

.icon-center {
  display: flex;
  align-items: center;
}

small {
  color: #5a5a5a;
}

.project-padding {
  padding-bottom: 0.5rem;
}

.project-spacing {
  padding-bottom: 8rem;
}

.project-back-button,
.project-view-more {
  color: #ffa10b;
  padding-bottom: 1rem;
}

@media only screen and (min-width: 50rem) {
  .project {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 0 1rem;
    padding: 0 2rem 8rem;
  }

  .project-title {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  .project-role {
    grid-column: 1 / 5;
    grid-row: 3 / 4;
  }

  .project-view-more {
    grid-column: 1 / 5;
    grid-row: 4 / 5;
  }

  .project-hero-image {
    grid-column: 1 / 5;
    grid-row: 6 / 7;
  }

  .project-hero-image-2 {
    grid-column: 5 / 9;
    grid-row: 6 / 7;
  }
}

@media only screen and (min-width: 70rem) {
  .project {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 0 1rem;
    padding: 0 2rem 8rem;
  }

  .project-title {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }

  .project-role {
    grid-column: 1 / 6;
    grid-row: 3 / 4;
  }

  .project-view-more {
    grid-column: 1 / 5;
    grid-row: 4 / 5;
  }

  .project-hero-image {
    grid-column: 1 / 7;
    grid-row: 6 / 7;
  }

  .project-hero-image-2 {
    grid-column: 7 / 13;
    grid-row: 6 / 7;
  }
}

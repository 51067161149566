.about {
  padding: 2rem 2rem;
}

.about-intro {
  font-family: neue-haas-grotesk-text, sans-serif;
}

.about-selected-works {
  padding-bottom: 4rem;
}

.about-padding,
.about-section-padding {
  padding-bottom: 2rem;
}

.about-link {
  padding-bottom: 0.5rem;
}

a.link-color {
  color: #ffa10b;
}

.list-padding {
  padding-bottom: 2rem;
}

.list-header-padding,
.list-item-padding {
  padding-bottom: 0.5rem;
}

@media only screen and (min-width: 50rem) {
  .about-padding,
  .about-section-padding {
    padding-bottom: 4rem;
  }

  .about {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(5, auto);
    gap: 0 1rem;
    padding: 2rem 2rem;
  }

  .about-selected-works-link {
    grid-column: 5 / 9;
    grid-row: 1 / 2;
  }

  .about-intro {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .about-potrait {
    grid-column: 6 / 9;
    grid-row: 2 / 5;
  }

  .about-info {
    grid-column: 1 / 6;
    grid-row: 3;
  }

  .about-list-item-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1rem;
    grid-column: 3 / 10;
  }

  .about-list-header {
    grid-column: 1 / 3;
  }

  .about-list-item-1 {
    grid-column: 1 / 3;
  }

  .about-list-item-2 {
    grid-column: 3 / 5;
  }

  .about-list-item-3 {
    grid-column: 5 / 7;
  }
}

@media only screen and (min-width: 70rem) {
  .about-padding,
  .about-section-padding {
    padding-bottom: 6rem;
  }
  .about {
    grid-template-columns: repeat(12, 1fr);
  }

  .about-selected-works-link {
    grid-column: 7 / 13;
    grid-row: 1 / 2;
  }

  .about-intro {
    grid-column: 1 / 9;
    grid-row: 2 / 3;
  }

  .about-potrait {
    grid-column: 9 / 13;
    grid-row: 2 / 5;
    object-fit: cover;
    height: auto;
    width: 100%;
    padding: 0;
  }

  .about-info {
    grid-column: 1 / 9;
    grid-row: 3 / 4;
  }

  .about-info-grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  .about-list-item-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 1rem;
    grid-column: 3 / 10;
  }

  .about-list-header {
    grid-column: 1 / 3;
  }

  .about-list-item-1 {
    grid-column: 1 / 3;
  }

  .about-list-item-2 {
    grid-column: 3 / 5;
  }

  .about-list-item-3 {
    grid-column: 5 / 8;
  }
}
